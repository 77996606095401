import React from "react";
import { useStyles } from "../styles/global";
import {
  Container,
  Typography,
  Grid,
  Box,
  useMediaQuery,
} from "@material-ui/core";

const InteractiveBrandPages = () => {
  const classes = useStyles();
  const largeScreen = useMediaQuery("(min-width:960px)"); //TODO: Use variable from theme

  return (
    <>
      <section id="brand-page" className={classes.interactiveBrandPages}>
        <Container className={classes.container}>
          <div className={classes.sectionIntroCentered}>
            <div className={classes.sectionIntroCenteredTitle}>
              <Typography variant="h2">Interactive Brand Pages</Typography>
            </div>
            <div className={classes.sectionIntroCenteredDescription}>
              <Box
                gridTemplateColumns={{
                  xs: "repeat(6, 1fr)",
                  md: "repeat(12, 1fr)",
                }}
                gridGap={{ xs: "18px", md: "36px" }}
                display={{ xs: "grid" }}
              >
                <Box gridColumn={{ xs: "1/-1", md: "4/-4" }}>
                  <Typography variant="body1">
                    Learn more about your favorite brands and chat directly on
                    Thursday, January 20.
                  </Typography>
                </Box>
              </Box>
            </div>
          </div>

          <Box
            gridTemplateColumns={{
              xs: "repeat(6, 1fr)",
              md: "repeat(12, 1fr)",
            }}
            gridGap={{ xs: "18px", md: "36px" }}
            display={{ xs: "grid" }}
            className={classes.redTest}
          >
            <Box gridColumn={{ xs: "1/-1", md: "2/-2" }}>
              <Grid
                container
                alignItems="center"
                direction={largeScreen ? "row" : "column"}
              >
                <Grid item={true} xs={12} md={6}>
                  <div className={classes.twoColumnImg}>
                    <img
                      src="https://static.tilegenerator.social/resources/interactive-brand-pages-demo.png"
                      alt="Interactive Brand Page demo"
                      title="Interactive Brand Page demo"
                    />
                  </div>
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <div className={classes.textSection}>
                    <div className={classes.textSectionBlock}>
                      <div className={classes.textSectionTitle}>
                        <Typography variant="h3">
                          Build Connections With Brands
                        </Typography>
                      </div>
                      <div className={classes.textSectionBody}>
                        <Typography variant="body1">
                          Believe in Music offers a unique opportunity to
                          discover the latest products, news and stories from
                          leading brands. Easy to browse brand pages showcase a
                          variety of content, fueling product discovery,
                          trendspotting, connections, inspiration and
                          opportunities.
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.textSectionBlock}>
                      <div className={classes.textSectionTitle}>
                        <Typography variant="h3">
                          Brand pages include:
                        </Typography>
                      </div>
                      <div className={classes.textSectionBody}>
                        <ul>
                          <li>
                            <span>Product galleries</span>
                          </li>
                          <li>
                            <span>Brand news</span>
                          </li>
                          <li>
                            <span>Job listings</span>
                          </li>
                          <li>
                            <span>Videos and more</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box gridColumn={{ xs: "1/-1", md: "2/-2" }}>
              <Grid
                container
                alignItems="center"
                direction={largeScreen ? "row" : "column-reverse"}
              >
                <Grid item={true} xs={12} md={6}>
                  <div className={classes.textSection}>
                    <div className={classes.textSectionBlock}>
                      <div className={classes.textSectionTitle}>
                        <Typography variant="h3">
                          Chat with your favorite brand
                        </Typography>
                      </div>
                      <div className={classes.textSectionBody}>
                        <Typography variant="body1">
                          Chat will be enabled on the opening day of the online
                          event, Thursday, January 20, creating a global
                          platform to interact directly with brand
                          representatives. The event will unite the global
                          music, sound and live events industry to network as we
                          look ahead to gathering in-person at The 2022 NAMM
                          Show, and welcome music enthusiasts from around the
                          world to learn more about the brands that fuel their
                          passion, lifestyles and careers.
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <div
                    className={`${classes.twoColumnImg} ${classes.twoColumnImgReverse}`}
                  >
                    <img
                      src="https://static.tilegenerator.social/resources/123-guitars.png"
                      alt="Brand page chat example between exhibitor and attendee"
                      title="Brand page chat example between exhibitor and attendee"
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default InteractiveBrandPages;
