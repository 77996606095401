import React from "react";
import { useStyles } from "../../../styles/global";
import {
  Container,
  Typography,
  Grid,
  Box,
  useMediaQuery,
  Link,
} from "@material-ui/core";

const Support = () => {
  const classes = useStyles();
  const largeScreen = useMediaQuery("(min-width:960px)"); //TODO: Use variable from theme

  return (
    <>
      <section id="promo" className={classes.support}>
        <Container className={classes.container}>
          <Box
            gridTemplateColumns={{
              xs: "repeat(6, 1fr)",
              md: "repeat(12, 1fr)",
            }}
            gridGap={{ xs: "18px", md: "36px" }}
            display={{ xs: "grid" }}
            className={classes.redTest}
          >
            <Box gridColumn={{ xs: "1/-1", md: "2/-2" }}>
              <Grid
                container
                alignItems="center"
                direction={largeScreen ? "row" : "column-reverse"}
              >
                <Grid item={true} xs={12} md={6}>
                  <div className={classes.textSection}>
                    <div className={classes.textSectionBlock}>
                      <div className={classes.textSectionTitle}>
                        <Typography variant="h2">Show Your Support</Typography>
                      </div>
                      <div className={classes.textSectionBody}>
                        <Typography variant="body1">
                          Spread the word about Believe in Music's exciting
                          events and education sessions through this helpful
                          promotional kit. Complete with social media assets and
                          a promotional tile generator, let the world know how
                          much you believe in music!
                        </Typography>
                      </div>
                      <div
                        className={`${classes.aCta} ${classes.oneVruMarginTop}`}
                      >
                        <Link
                          href="https://www.namm.org/believe-in-music/promo-kit"
                          underline="none"
                        >
                          View Promotional Kit
                        </Link>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <div
                    className={`${classes.twoColumnImg} ${classes.twoColumnImgReverse}`}
                  >
                    <img
                      src="https://static.tilegenerator.social/resources/show-your-support-4.png"
                      alt="Show Your Support"
                      title="Show Your Support"
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default Support;
