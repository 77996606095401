import React from "react";
import { useStyles } from "../../../styles/global";
import { Container, Typography, Grid, Box, useMediaQuery, Link } from "@material-ui/core";

const Schedule = () => {
  const classes = useStyles();
  const largeScreen = useMediaQuery("(min-width:960px)"); //TODO: Use variable from theme

  return (
    <>
      <section id="schedule" className={classes.schedule}>
        <Container className={classes.container}>

          <Box
            gridTemplateColumns={{
              xs: "repeat(6, 1fr)",
              md: "repeat(12, 1fr)",
            }}
            gridGap={{ xs: "18px", md: "36px" }}
            display={{ xs: "grid" }}
          >
            <Box gridColumn={{ xs: "1/-1", md: "2/-2" }}>
              <Grid
                container
                alignItems="center"
                direction={largeScreen ? "row" : "column"}
              >
                <Grid item={true} xs={12} md={6}>
                  <div className={classes.twoColumnImg}>
                    <img
                      src="https://static.tilegenerator.social/resources/build-your-schedule_1.png"
                      alt="Build Your Schedule"
                      title="Build Your Schedule"
                    />
                  </div>
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <div className={classes.textSection}>
                    <div className={classes.textSectionBlock}>
                      <div className={classes.textSectionTitle}>
                        <Typography variant="h2">
                          Build Your Schedule
                        </Typography>
                      </div>
                      <div className={classes.textSectionBody}>
                        <Typography variant="body1">
                          Access to the Believe in Music platform will open on January 17, but you can start planning your event now by viewing our schedule page. Find events and education for your specific interests, by filtering by audience, tags, programs or custom search terms.
                        </Typography>
                      </div>
                      <div className={`${classes.aCta} ${classes.oneVruMarginTop}`}>
                        <Link
                          href="/schedule"
                          underline="none"
                        >
                          View Schedule
                        </Link>
                      </div>
                    </div>

                  </div>
                </Grid>
              </Grid>
            </Box>

          </Box>
        </Container>
      </section>
    </>
  );
};

export default Schedule;
