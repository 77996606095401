import React from 'react';
import Container from '@material-ui/core/Container';
import { useStyles } from '../../../styles/global';


const FeaturedVideo = () => {
  const classes = useStyles();
  return (
    <>
        <Container maxWidth="md">
          <div className={classes.featuredVideo}>
            <div className={classes.featuredVideoIframe}>
              <iframe title="video" width="100%" height="100%" src="https://ww1.namm.org/video/embed/7627" frameBorder="0" scrolling="auto" />
              <div className={classes.space169}>
                <img src="https://static.tilegenerator.social/s3/16-9-space.png" alt=""/>
              </div>
            </div>
          </div>
        </Container>
    </>
  );
}

export default FeaturedVideo;

