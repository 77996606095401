import React from "react";
import { useStyles } from "../styles/global";
import { Container, Typography, Grid } from "@material-ui/core";

const EventPilars = () => {
  const classes = useStyles();

  return (
    <>
      <section className={classes.eventPilars}>
        <Container>
          <div className={classes.containerInner}>
            <Grid container spacing={0} className={classes.eventPilarsContent}>
              <Grid
                item={true}
                xs={12}
                md={4}
                className={classes.eventPilarsCard}
              >
                <div className={classes.eventPilarsCardInner}>
                  <div className={classes.eventPilarsCardImg}>
                    <img
                      src="https://static.tilegenerator.social/resources/believe-in-music-tv-pillar.png"
                      alt="Believe In Music TV"
                      title="Believe In Music TV"
                    />
                  </div>
                  <div className={classes.eventPilarsCardInfo}>
                    <div className={classes.eventPilarsCardTitle}>
                      <Typography variant="h2">Believe In Music TV</Typography>
                    </div>
                    <div className={classes.eventPilarsCardInfo}>
                      <Typography variant="body1">
                        Experience livestreamed music, interviews and
                        entertainment from around the world.
                      </Typography>
                    </div>
                    <div className={classes.aCta}>
                      <a href="#tv">Learn More</a>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                item={true}
                xs={12}
                md={4}
                className={classes.eventPilarsCard}
              >
                <div className={classes.eventPilarsCardInner}>
                  <div className={classes.eventPilarsCardImg}>
                    <img
                      src="https://static.tilegenerator.social/resources/believe-in-music-education-pillar.png"
                      alt="Education"
                      title="Education"
                    />
                  </div>
                  <div className={classes.eventPilarsCardInfo}>
                    <div className={classes.eventPilarsCardTitle}>
                      <Typography variant="h2">Education</Typography>
                    </div>
                    <div className={classes.eventPilarsCardInfo}>
                      <Typography variant="body1">
                        Participate in multiple sessions across topic tracks.
                      </Typography>
                    </div>
                    <div className={classes.aCta}>
                      <a href="#education">Learn More</a>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                item={true}
                xs={12}
                md={4}
                className={classes.eventPilarsCard}
              >
                <div className={classes.eventPilarsCardInner}>
                  <div className={classes.eventPilarsCardImg}>
                    <img
                      src="https://static.tilegenerator.social/resources/believe-in-music-interactive-brand-page.png"
                      alt="Interactive Brand Pages"
                      title="Interactive Brand Pages"
                    />
                  </div>
                  <div className={classes.eventPilarsCardInfo}>
                    <div className={classes.eventPilarsCardTitle}>
                      <Typography variant="h2">
                        Interactive Brand Pages
                      </Typography>
                    </div>
                    <div className={classes.eventPilarsCardInfo}>
                      <Typography variant="body1">
                        Learn more and connect with your favorite brands.
                      </Typography>
                    </div>
                    <div className={classes.aCta}>
                      <a href="#brand-page">Learn More</a>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
    </>
  );
};

export default EventPilars;
